* {
  padding: 0;
  margin: 0;
}

.navbar {
  background-color: /*var(--secondary-color)*/ #19222b;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 4em;
}

#logo {
  font-family: 'Press Start 2P', cursive;
  font-size: 1.3em;
  color:#fff;
  margin-left: 1em;
  display:inline-block;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  border: none;
  text-align: center;
  order: 0;
}

#logo::first-letter {
  color: #42bcf4;
}

.user-info {
  box-sizing: content-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  padding-right: 0.3em;
}

.user-info:after {
  color: #fff;
}

.user-info .username {
  font-size: 1.3em;
  margin-left: 0.8em;
}

.user-info .user-profile-picture {
  order: -10;
  width: 2em;
  height: 2em;
  object-fit: cover;
  border-radius: 2px;
  /*visibility: hidden;*/ 
}

.user-info-container {
  position: relative;
  box-sizing: content-box;
  /*padding-left: 0.9em;*/
  /*padding-right: 1.5em;*/
  z-index: 10;
  height: 100%;
  width: 10em;
  min-width: auto;
}

.user-info-container *:hover {
  background-color: #141c24;
}

.user-info-container ul {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 3px 3px;
  z-index: 10;
  /*width: 13em;*/
  width: 100%;
}

.user-info-container ul li {
  opacity: 0;
  visibility: hidden;
  display: none;
  transition: all 0.1s ease-in-out;
  border: 1px solid #40576e;
}

.user-info-container.opened-menu ul > li {
  opacity: 1;
  visibility: visible;
  display: flex;
}

.user-info-container.closed-menu li {
  opacity: 0;
  visibility: hidden;
  display: none;
}
/*.user-info-container ul:hover {
  background-color: #15191c;
}*/

.user-info-container li {
  list-style-type: none;
  cursor: pointer;
  font-size: 1.1em;
  padding: 0.5em 1em;
  color: #fff;
  background-color: var(--secondary-color);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.up-to-date-container {
  /*margin: 0.5em 1em 0.5em 0;*/
  margin: 0;
  padding: 0.5em 1em 0.5em 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #40576e;
  border-top: 1px solid #40576e;
  background-color: var(--secondary-color);
  
}

.up-to-date-text {
  margin-right: 1em;
  font-size: 1.05em;
  font-weight: bold;
  font-family: 'Rubik', sans-serif;
  color: #fff;
  /*color: var(--secondary-color);*/
}

.sign-out-btn-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 0 0 4px 4px;
}

.visit-profile-btn-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.visit-profile-btn {
  font-size: 0.9em;
  text-decoration: none;
  color: #fff;
}

/* Desktop styles */
@media screen and (min-width: 960px) {
  /*.menu-toggle {
    display: none;
  }*/

  .navbar {
    background-color: var(--secondary-color);
  }

  #logo {
    font-size: 1.5em;
    margin-left: 0.9em;
  }

  .menu.opened-menu {
    border-bottom: none;
  }

  /*.user-info-container {
    display: block;
    width: auto;
    border-bottom: none;
    order: 5;
    margin-left: auto;
  }*/

  .user-info-container {
    width: 12em;
  }

  .user-info-container:hover {
    background-color: var(--secondary-color);
  }

  .user-info .username {
    margin-right: 0.7em;
  }

  .user-info .user-profile-picture {
    order: 0;
  }

  .up-to-date-container {
    margin: 0.7em 0.5em 0 0;
    background-color: var(--primary-color);
    border: none;
  }

  .up-to-date-text {
    color: #d9d9d9;
    font-size: 1.2em;
  }

  .user-info-container:hover ul > li {
    opacity: 1;
    visibility: visible;
    display: flex;
  }
}