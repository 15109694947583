
@media screen and (min-width: 960px) {
  .anime-list {
    max-width: 99.3%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 220px);
    grid-gap: 1.2em;
    justify-content: center;
  }
}

.empty-list {
  margin-top: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.empty-list p {
  font-size: 30px;
}

.empty-list .empty-list-face {
  font-size: 60px;
}