html {
  font-size: 16px;
}

:root {
  --primary-color: #3BADD1;
  --secondary-color: #22303d;
  --text-color: #fff;
  --uptodate-color: #911414;
  --uptodate-text-color: #f9c518;
}

body {
  background-color: #3BADD1;
}

.anime-container {
  background-color: var(--secondary-color);
  width: 100vw;
  max-width:100%;
  color: var(--text-color);
  height: 130px;
  border-bottom: 1px solid #40576e;
  position: relative;
  overflow: hidden; /* Para no mostrar como slidea el anime secondary container */
}

.anime {
  display: flex;
  height: 100%;
  width: 100%;
}

.anime-img {
  height:80%;
  min-width: 80px;
  margin: auto 0 auto 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 3px;
}

.anime-info {
  height: 100%;
  margin: 5px 0 0 5px;
}

.anime-prop {
  margin: 5px 5px 5px 10px;
  text-align: left;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: var(--text-color);
  font-size: 0.9em;
}

.anime-title {
  font-size: 1.1em;
  /*line-height: 1.1em;*/
  /*height: 2.2em;*/
  /*overflow: hidden;*/
  color: #42bcf4;
  margin-bottom: 0.6em;
}

/* up to date anime styles */

.anime-container.uptodate .anime {
  background-color: var(--uptodate-color);
}

.anime-container.uptodate .anime .anime-info .anime-title {
  color: var(--uptodate-text-color);
}

/* secondary view styles */
.anime-secondary {
  background-color:#2B4058;
  border-radius: 4px;
  z-index:2;
  width:100%;
  height:100%;
  max-height: 100%;
  margin:auto;
  position:absolute;
  top:0px;
  left:0px;
  display: grid;
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0em;
  grid-row-gap: 0.2em;
}

.anime-secondary.opened {
  transform: translateX(0%);
  transition: .5s ease-in-out;
}

/*.visit-btn { grid-area: 1 / 1 / 2 / 2; }*/
.watched-btn { grid-area: 1 / 1 / 2 / 2; }
.drop-btn { grid-area:  2 / 1 / 3 / 2; }
.score-input { grid-area: 1 / 2 / 2 / 3; }
.score-btn { grid-area: 2 / 2 / 3 / 3; } 
.update-message { grid-area: 3 / 2 / 4 / 3; }

.anime-btn {
  text-decoration: none;
  box-sizing: border-box;
  border: none;
  font-family: 'Fira Sans', sans-serif;
  font-size: 0.8em;
  color: #fff;
  /*background-color: #cb4e4e;*/
  background-color: #4ea5cb;
  box-shadow: 0 6px #3b80aa;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 4px;
  text-align: center;
  width: 60%;
  max-width: 60%;
  height: 2em;
  padding: 0.5em;
  align-self: center;
  justify-self: center;
}

.anime-btn:hover {
  box-shadow: 0 4px #3b80aa;
  top: 2px;
}

.anime-btn:active {
  box-shadow: 0 0 #3b80aa;
  top: 6px;
}

.score-input {
  background: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.2em;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  height: 1.5em;
  box-sizing: border-box;
  width: 60%;
  opacity: 1;
  text-indent: 20px;
  transition: all .2s ease-in-out;
  display: block;
  margin: 0 auto 0px auto;
  align-self: center;
  justify-self: center;
}

.anime-btn.visit-btn {
  display: none;
}

.update-message {
  margin: 15px 5px 5px 10px;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  font-weight: normal;
  color: var(--text-color);
  font-size: 0.9em;
}

.update-message.error {
  color: #c92626;
}

.update-message.success {
  color: #1fd11f;
}

.update-message.warning {
  color: #e0bb00;
}

/* Desktop styles */
@media screen and (min-width: 1200px) {
  
}

/* Tablet/Laptop styles */
@media screen and (min-width: 960px) {
  html {
    font-size: 16px;
  }

  .anime {
    display: inline-block;
    height: auto; 
    /*margin: 20px 10px 35px 10px; LO MOVI A .anime-container
    width:220px;*/
    background-color: #22303d;
    border-bottom: none;
    padding-bottom: 10px;
  }
  
  .anime-img {
    min-height:305px;
    max-height:305px;
    margin: 0;
    border-radius: 4px 4px 0 0;
    display:block;
  }
  
  .anime-info {
    height: auto;
  }

  .anime-prop {
    text-align: left;
    font-weight: normal;
    font-size: 0.95em;
  }

  .anime-title {
    font-size: 1.05em;
    height: 2.1em;
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }

  /* anime secondary screen styles */

  .anime-container {
    border-radius: 4px 4px 7px 7px;
    position: relative;
    height: auto;
    margin: 20px 10px 35px 10px;
    width: 220px;
    overflow: hidden; /* Para no mostrar como slidea el anime secondary container */
  }
  
  .anime-secondary {
    background-color:#2B4058;
    border-radius: 4px;
    z-index:2;
    width:100%;
    height:100%;
    margin:auto;
    position:absolute;
    top:0px;
    left:0px;
    display: block;
    transform: translateY(-100%);
    transition: all 0.5s ease-in-out;
  }

  .anime-secondary.opened {
    transform: translateY(0%);
    transition: .5s ease-in-out;
  }

  .anime-btn {
    font-size: 1em;
    padding: 12px 10px;
    height: auto;
    width: 80%;
    max-width: 80%;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .anime-btn:hover {
    box-shadow: 0 4px #3b80aa;
    top: 2px;
  }

  .anime-btn:active {
    box-shadow: 0 0 #3b80aa;
    top: 6px;
  }

  .anime-btn.visit-btn {
    display: block;
  }

  .score-input {
    background: #fff;
    border: none;
    border-radius: 4px;
    font-size: 21px;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
    height: 42px;
    width: 80%;
    opacity: 1;
    text-indent: 20px;
    transition: all .2s ease-in-out;
    display: block;
    margin: 25px auto 0px auto;
  }

  .update-message {
    margin: 15px 5px 5px 10px;
    text-align: center;
    font-family: 'Rubik', sans-serif;
    font-weight: normal;
    color: var(--text-color);
    font-size: 0.9em;
  }

  .update-message.error {
    color: #c92626;
  }

  .update-message.success {
    color: #1fd11f;
  }
  
  .update-message.warning {
    color: #e0bb00;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-13 2:12:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slides
 * ----------------------------------------
 */
 @-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes slide-up {
  0% {
    display:block;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    display: none;
  }
}
@keyframes slide-up {
  0% {
    display:block;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    display: none;
  }
}

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slide-left {
  0% {
    display:flex;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    display: none;
  }
}
@keyframes slide-left {
  0% {
    display:flex;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    display: none;
  }
}

