
.login {
  /*padding-top: 30vh;*/
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  max-width: 90%;
  padding: 3em;
  background-color: #004663;
  color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  
}

.welcome-message .login-logo {
  /*font-family: 'PT Sans Caption', sans-serif;*/
}

.welcome-message h2.login-logo::first-letter {
  color: #42bcf4;
}

.welcome-message h2 {
  font-family: 'PT Sans Caption', sans-serif;
  display: inline;
}

.welcome-message {
  margin-bottom: 3em;
  text-align: center;
}

.description-message {
  font-family: 'PT Sans Caption', sans-serif;
  margin-bottom: 3em;
  text-align: center;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.login-button {
  font-family: 'PT Sans Caption', sans-serif;
  font-size: 1.1em;
  font-weight: 700;
  color: #fff;
  background-color: #2E51A2;
  width: 240px;
  height: 70px;
  margin: 0 auto;
  padding: 0.2em;
  border: 1px solid #fff;
}

.login-message {
  max-width:100%;
  font-family: 'PT Sans Caption', sans-serif;
  font-size: 1.1em;
  font-weight: 700;
  color: #f2c600;
  text-align: center;
  padding-top: 1em;
}

.login-footer-container {
  position: absolute;
  height: 300px;
  width: 100vw;
  top: calc(100vh - 300px);
}

.login-footer-img {
  /*transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
  -webkit-transform: scale(0.8, 0.8);*/
  position: absolute;
  height: 300px;
  width: 100vw;
  top: calc(100vh - 300px);
  z-index: -10;
}

@media (min-width: 1250px) { 
  .login-footer-img {
    width: 75vw;
  }
}

@media (min-width: 1600px) { 
  .login-footer-img {
    width: 65vw;
  }
}